import { useNavigate } from "react-router-dom"

const NavItem = ({ icon, label, active, link = '/' }) => {
  const navigate = useNavigate()

  const handleClick = () => navigate(link)

  return (
    <button
    className={`flex items-center space-x-3 w-full px-3 py-2 rounded-lg transition-colors ${
    active ? 'bg-gray-100' : 'text-gray-600 hover:bg-gray-50'
    }`}
    onClick={handleClick}
  >
    {icon}
    <span className="font-medium">{label}</span>
  </button>
  )
}

export default NavItem