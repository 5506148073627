import { 
  Search,
  Bell,
  User
} from 'lucide-react';

const TopNavbar = () => {

  return (
    <div className="px-6 py-4 flex items-center justify-between border-b border-gray-200">
      <div className="flex-1 max-w-2xl">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
          type="text"
          placeholder="Mencari disini..."
          className="w-full pl-10 pr-4 py-2 bg-gray-50 rounded-lg focus:outline-none"
          />
        </div>
      </div>
      
      <div className="flex items-center space-x-4">
        <button className="relative">
          <Bell size={24} className="text-gray-600" />
          <span className="absolute -top-1 -right-1 bg-[#FF3B30] text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">1</span>
        </button>

        <User size={24} className="text-gray-600" />

        <div className="text-gray-600 hidden md:block">PT Abc Def</div>

        <select className="border-none bg-transparent">
          <option>ID</option>
          <option>EN</option>
        </select>
      </div>
    </div>
  );
}

export default TopNavbar