import React from 'react';
import {
 Search,
 Bell,
 User,
 FileText,
 Clock4,
 ArrowUpDown,
 Download,
 HelpCircle,
 ChevronDown,
 MoreHorizontal,
 CheckCircle,
 Upload
} from 'lucide-react';

import { Sidebar, TopNavbar } from '../../components';

const Reconciliation = () => {
   
   const SummaryCard = ({ title, leftValue, leftLabel, rightValue, rightLabel, value, valueColor }) => (
    <div className="bg-white p-4 rounded-lg border border-gray-200">
    <h4 className="font-medium mb-3">{title}</h4>
    {value ? (
    <div className={`text-lg font-medium ${valueColor || 'text-gray-900'}`}>
    {value}
    </div>
    ) : (
    <div className="space-y-2">
    <div>
    <div className="text-lg font-medium">{leftValue}</div>
    <div className="text-sm text-gray-500">{leftLabel}</div>
    </div>
    <div>
    <div className="text-lg font-medium">{rightValue}</div>
    <div className="text-sm text-gray-500">{rightLabel}</div>
    </div>
    </div>
    )}
    </div>
   );
   
   const InvoiceCard = ({
    type,
    number,
    date,
    company,
    npwp,
    dpp,
    ppn,
    ppnbm,
    status,
    matched
   }) => (
    <div className={`border rounded-lg p-4 ${matched ? 'bg-green-50' : 'bg-white'}`}>
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-start space-x-3">
          <FileText size={20} className="text-gray-400 mt-1" />
          <div>
            <div className="font-medium">{number}</div>
            <div className="text-sm text-gray-500">{date}</div>
            <div className="text-sm text-gray-500">{company}</div>
            <div className="text-sm text-gray-500">{npwp}</div>
          </div>
        </div>
      {type === 'incoming' && matched && (
      <CheckCircle size={20} className="text-green-500" />
      )}
      </div>

      <div className="space-y-1">
        <div className="flex justify-between text-sm">
          <span>DPP (Rp)</span>
          <span>: {dpp}</span>
        </div>
      <div className="flex justify-between text-sm">
      <span>PPN (Rp)</span>
      <span>: {ppn}</span>
      </div>
      <div className="flex justify-between text-sm">
      <span>PPnBM (Rp)</span>
      <span>: {ppnbm}</span>
      </div>
      </div>

      <div className="mt-4 flex flex-col sm:flex-row sm:justify-between items-center">
        <button className="text-blue-600 text-sm">Lihat detail</button>
        {type === 'incoming' && (
          <div className="flex items-center justify-normal sm:justify-between space-x-3 mt-3 sm:mt-0">
            <button className="flex items-center text-sm">
              <Upload size={16} className="mr-1" />
              Upload ke DJP
            </button>

            <button>
              <MoreHorizontal size={20} />
            </button>

            {status && (
              <span className="text-xs text-yellow-600 flex items-center">
                <div className="w-2 h-2 bg-yellow-600 rounded-full mr-1"></div>
                {status}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
   );
   

  return (
    <div className="flex h-screen bg-white">
      {/* Left Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        {/* Top Bar */}
        <TopNavbar />

      {/* Page Content */}
        <div className="p-6">
          {/* Breadcrumb & Header */}
          <div className="flex items-center text-sm text-gray-600 mb-4">
            <span>Daftar Rekonsiliasi</span>
            <span className="mx-2">/</span>
            <span>Rekonsiliasi Faktur Masukan</span>
          </div>

          {/* Page Title & Actions */}
          <div className="flex flex-col mb-6 lg:flex-row lg:justify-between lg:items-center">
            <div>
              <h1 className="text-xl font-bold mb-1">Rekonsiliasi Faktur Masukan</h1>
              <div className="text-xs sm:text-sm text-gray-600">Masa pajak 10/2021</div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="flex items-center text-gray-600 text-xs sm:text-sm">
                <Clock4 size={16} className="mr-1 hidden sm:block" />
                Update terakhir 10-11-2021 17:57
              </div>

              <button className="flex items-center px-3 py-1 border border-gray-300 rounded-lg text-xs sm:text-sm">
                <ArrowUpDown size={16} className="mr-1" />
                Update data
              </button>

              <button className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg">
                <Download size={20} />
              </button>

              <button className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg">
              <HelpCircle size={20} />
              </button>
            </div>
          </div>

          {/* Tabs & Filters */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-8">
              <div className="border-b-2 border-orange-500 pb-2 font-medium">
                Rekonsiliasi <span className="bg-gray-100 px-2 py-0.5 rounded-full text-sm">5</span>
              </div>
              <button className="text-gray-600 flex items-center">
                Sumber data
                <ChevronDown size={16} className="ml-1" />
              </button>
            </div>
          </div>

          {/* Summary Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 lg:gap-6 mb-6">
            <SummaryCard
              title="DPP"
              leftValue="2.154.545,00"
              leftLabel="Total DPP faktur pembelian (Rp)"
              rightValue="2.154.545,00"
              rightLabel="Total DPP faktur masukan (Rp)"
            />
            <SummaryCard
              title="Pajak"
              leftValue="215.455,00"
              leftLabel="Total pajak faktur pembelian (Rp)"
              rightValue="215.454,00"
              rightLabel="Total pajak faktur masukan (Rp)"
            />
            <SummaryCard
              title="Selisih pajak"
              value="1,00"
              valueColor="text-red-500"
            />
          </div>

          {/* Filters & Search */}
          <div className="flex flex-col space-y-3 lg:flex-row lg:justify-between lg:space-y-0 mb-6">
            <button className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-lg">
              <span>Tampilkan semua data</span>
              <ChevronDown size={16} className="ml-2" />
            </button>

            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
              <input
                type="text"
                placeholder="Cari no transaksi, nama, NPWP"
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full"
              />
            </div>
          </div>

          {/* Invoice Lists */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Purchase Invoices */}
            <div>
              <h3 className="font-medium mb-4">Faktur pembelian (5)</h3>
              <div className="space-y-4">
                <InvoiceCard
                  type="purchase"
                  number="10031"
                  date="10/10/2021"
                  company="CV Abadi"
                  npwp="71.103.528.2-416.000"
                  dpp="454.545,00"
                  ppn="45.455,00"
                  ppnbm="0,00"
                  matched={false}
                />
                <InvoiceCard
                  type="purchase"
                  number="10027"
                  date="04/10/2021"
                  company="CV Abadi"
                  npwp="71.103.528.2-416.000"
                  dpp="500.000,00"
                  ppn="50.000,00"
                  ppnbm="0,00"
                  matched={true}
                />
              </div>
            </div>

            {/* Incoming Invoices */}
            <div>
              <h3 className="font-medium mb-4">Faktur masukan (5)</h3>
              <div className="space-y-4">
                <InvoiceCard
                  type="incoming"
                  number="010.001-21.00000003"
                  date="10/10/2021"
                  company="CV Abadi"
                  npwp="71.103.528.2-416.000"
                  dpp="454.545,00"
                  ppn="45.454,00"
                  ppnbm="0,00"
                  status="BELUM APPROVED DJP"
                  matched={false}
                />
                <InvoiceCard
                  type="incoming"
                  number="010.000-21.00100657"
                  date="04/10/2021"
                  company="CV Abadi"
                  npwp="71.103.528.2-416.000"
                  dpp="500.000,00"
                  ppn="50.000,00"
                  ppnbm="0,00"
                  status="BELUM APPROVED DJP"
                  matched={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reconciliation