import { useState } from 'react';

import {
  Grid, 
  FileText, 
  Clock, 
  RefreshCw, 
  Book, 
  MessageCircle, 
  Settings,
  LayoutDashboard,
  MessageSquare,
} from 'lucide-react';

import NavItem from './NavItem';

const Sidebar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const sidebarItems = [
    { icon: <LayoutDashboard size={20} />, label: "Dashboard", link: '/' },
    { icon: <FileText size={20} />, label: "Kepatuhan Pajak", link: '/tax-compliance' },
    { icon: <Clock size={20} />, label: "Rekapitulasi Pajak", link: '/tax-recapitulation' },
    { icon: <RefreshCw size={20} />, label: "Rekonsiliasi Pajak", link: '/tax-reconciliation' },
    { icon: <Book size={20} />, label: "Regulasi Pajak", link: '/tax-regulations' },
    { icon: <MessageSquare size={20} />, label: "Konsultasi Pajak", link: '/tax-consultation' },
    { icon: <Settings size={20} />, label: "Pengaturan", link: '/setting' }
  ];

  const currenPath = window.location.pathname

  return (
    <>
      <button
        className="sm:hidden fixed bottom-4 left-4 z-50 p-2 bg-gray-100 border border-black rounded-md shadow-md"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle Sidebar"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>

      <div
        className={`fixed top-0 left-0 h-full bg-white border-r border-gray-200 p-4 z-40 transition-transform transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0 sm:relative sm:w-64`}
      >
        <div className="mb-8">
          <h1 className="text-2xl font-bold">ClearTax</h1>
        </div>

        <nav className="space-y-2">
          {sidebarItems.map((item, index) => (
            <NavItem
              key={index}
              icon={item.icon}
              label={item.label}
              active={currenPath === item.link}
              link={item.link}
            />
          ))}
        </nav>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 sm:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  )
}

export default Sidebar