import React from 'react';

import {
  FileText,
  MessageCircle,
  Calendar
} from 'lucide-react';

import { Sidebar, TopNavbar } from '../../components';

const Dashboard = () => {

  const NotificationCard = ({ number, text, bgColor, textColor }) => (
    <div className={`${bgColor} p-4 rounded-lg`}>
      <div className={`${textColor} text-2xl font-bold mb-1`}>{number}</div>
      <div className="text-gray-600">{text}</div>
    </div>
  );
   
  const TransactionItem = ({ label, amount, date, type }) => (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center space-x-3">
        <FileText size={20} className="text-gray-400" />
        <div>
        <p className="font-medium">{label}</p>
        <p className="text-sm text-gray-500">{date}</p>
        </div>
      </div>
      <span className={`font-medium ${type === 'expense' ? 'text-gray-900' : 'text-green-500'}`}>{amount}</span>
    </div>
  );
   
  const QuickActionButton = ({ label, icon }) => (
    <button className="flex items-center space-x-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
      {icon}
      <span>{label}</span>
    </button>
  );

  return (
    <div className="flex h-screen bg-white">
      {/* Left Sidebar */}
      <Sidebar />
   
      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        {/* Top Bar */}
        <TopNavbar />
        
    
        {/* Dashboard Content */}
        <div className="p-6">
        {/* Compliance Overview */}
          <div className="mb-8 flex flex-col lg:flex-row lg:justify-between">
            <div className="w-full lg:w-1/3">
              <h2 className="text-lg font-medium mb-4">Ikhtisar Kepatuhan</h2>

              <div className="relative w-48 h-48 mx-auto lg:mx-0">
                <div className="absolute inset-0">
                  <svg viewBox="0 0 100 100" className="transform -rotate-90">
                    <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    stroke="#FF3B30"
                    strokeWidth="10"
                    strokeDasharray="141.3717"
                    strokeDashoffset="35.34293"
                    />
                    <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    stroke="#34C759"
                    strokeWidth="10"
                    strokeDasharray="141.3717"
                    strokeDashoffset="106.02878"
                    />
                  </svg>

                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-2xl font-bold">25%</span>
                  </div>
                </div>
              </div>

              <div className="mt-4 space-y-2">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-[#34C759] mr-2"></span>
                    <span>Patuh</span>
                  </div>
                <span>5</span>
                </div>

                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-yellow-400 mr-2"></span>
                    <span>Perlu ditinjau</span>
                  </div>
                  <span>5</span>
                </div>

                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <span className="w-3 h-3 rounded-full bg-[#FF3B30] mr-2"></span>
                    <span>Tidak patuh</span>
                  </div>
                  <span>10</span>
                </div>

              </div>
              <button className="mt-4 text-blue-500">Lihat</button>
            </div>
        
            <div className="w-full lg:w-2/3 space-y-4">
              <NotificationCard
              number="3"
              text="Mendatang Tenggat waktu"
              bgColor="bg-blue-50"
              textColor="text-blue-600"
              />
              <NotificationCard
              number="2"
              text="Memperbarui Pajak Terkini"
              bgColor="bg-green-50"
              textColor="text-green-600"
              />
              <NotificationCard
              number="1"
              text="Konsultasi Tertunda"
              bgColor="bg-purple-50"
              textColor="text-purple-600"
              />
            </div>
          </div>
      
        {/* Recent Transactions */}
          <div className="mb-8">
            <h2 className="text-lg font-medium mb-4">Transaksi Terkini</h2>
            <div className="space-y-4">
              <TransactionItem
              label="SPT Masa PPN"
              amount="-Rp24.656.000"
              date="20 Juli"
              type="expense"
              />
              <TransactionItem
              label="PPh 21 Karyawan"
              amount="+Rp11.567.000"
              date="20 Juli"
              type="income"
              />
              <TransactionItem
              label="Pembayaran PBB"
              amount="-Rp27.156.000"
              date="20 Juli"
              type="expense"
              />
            </div>
          </div>
      
        {/* Quick Actions */}
          <div>
            <h2 className="text-lg font-medium mb-4">Tindakan Cepat</h2>
            <div className="flex space-x-4">
              <QuickActionButton label="Bayar Pajak" icon={<FileText size={18} />} />
              <QuickActionButton label="Lihat Kalendar" icon={<Calendar size={18} />} />
              <QuickActionButton label="Konsultasi" icon={<MessageCircle size={18} />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard