import React, { useEffect, useState } from 'react';
import {
  MessageSquare,
  BookOpen,
  Clock,
  Save,
  HelpCircle,
  Star,
  Send,
} from 'lucide-react';

import { Sidebar, TopNavbar } from '../../components';

const Consultation = () => {
   
  const SuggestedQuestion = ({ question }) => (
    <button className="w-full text-left p-3 bg-white rounded-lg hover:bg-gray-50 transition-colors">
      {question}
    </button>
  );
   
  const PopularTopic = ({ label, count }) => (
    <div className="flex items-center justify-between p-2 hover:bg-gray-100 rounded-lg">
      <span>{label}</span>
      <span className="text-gray-500 text-sm">{count} discussions</span>
    </div>
  );
   
  const ExpertCard = ({ name, specialty, rating }) => (
    <div className="flex items-center space-x-3 p-3 bg-white rounded-lg">
      <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
      <div className="flex-1">
        <h4 className="font-medium">{name}</h4>
        <p className="text-sm text-gray-500">{specialty}</p>
      </div>
      <div className="flex items-center space-x-1">
        <Star size={16} className="text-yellow-400 fill-current" />
        <span className="font-medium">{rating}</span>
      </div>
    </div>
  );

  const [message, setMessage] = useState('')

  const handleChange = (e) => {
    const value = e.target.value
    if (value.length <= 900) setMessage(value.slice(0, 900));
  }

  return (
    <div className="flex h-screen bg-white">
      {/* Left Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        {/* Top Bar */}
        <TopNavbar />
    
        {/* Consultation Header */}
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex flex-col md:flex-row md:justify-between items-center">
            <h1 className="text-xl font-bold text-blue-600">Konsultasi Pajak (Tax Consultations)</h1>
            <div className="flex items-start md:items-center space-x-4 mt-3 md:mt-0">
              <button className="flex items-center space-x-2 text-sm lg:text-base text-gray-600">
                <Save size={18} />
                <span>Tersimpan</span>
              </button>

              <button className="flex items-center space-x-2 text-sm lg:text-base text-gray-600">
                <Clock size={18} />
                <span>Riwayat Pertanyaan</span>
              </button>
            </div>
          </div>
      
          {/* Quick Access Buttons */}
          <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0 mt-4">
            <button className="flex items-center space-x-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg">
              <MessageSquare size={18} />
              <span>Tax</span>
            </button>

            <button className="flex items-center space-x-2 px-4 py-2 bg-gray-50 text-gray-600 rounded-lg">
              <BookOpen size={18} />
              <span>Eksplorasi Peraturan</span>
            </button>

            <button className="ml-auto px-4 py-2 bg-pink-500 text-white rounded-lg sm:text-xs lg:text-base">
              Minta Konsultasi Ahli
            </button>
          </div>
        </div>
    
        {/* Main Consultation Area */}
        <div className="p-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
        {/* Left Column - Chat & Suggestions */}
          <div className="col-span-2 space-y-6">
            {/* Example Questions */}
            <div className="bg-blue-50 p-4 rounded-lg space-y-4">
              <h3 className="font-medium">Mulai dengan contoh pertanyaan dibawah ini:</h3>

              <div className="space-y-2">
                <SuggestedQuestion
                question="Apakah warga negara asing bisa menjadi direktur di bidang sumber daya manusia?"
                />
                <SuggestedQuestion
                question="Apa yang dimaksud dengan persetujuan dalam Undang-Undang Data Pribadi?"
                />
                <SuggestedQuestion
                question="Apakah suatu perjanjian dapat dibatalkan secara sepihak?"
                />
              </div>
            </div>
        
            {/* Chat Input */}
            <div className="border rounded-lg">
              <div className="p-4 min-h-[200px]">
                <textarea
                  value={message}
                  placeholder="Sampaikan pertanyaan Anda seputar hukum sekarang"
                  className="w-full h-full resize-none border-none focus:outline-none"
                  onChange={handleChange}
                  rows={7}
                />
              </div>

              <div className="border-t p-4 flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <div className="flex items-center justify-between sm:justify-normal space-x-4 text-gray-500">
                  <button className="flex items-center space-x-1">
                    <HelpCircle size={16} />
                    <span>Disclaimer</span>
                  </button>

                  <button className="flex items-center space-x-1">
                    <HelpCircle size={16} />
                    <span>FAQ</span>
                  </button>
                </div>

                <div className="flex items-center justify-between sm:justify-normal space-x-2 mt-2 sm:mt-0">
                  <span className="text-gray-400">{`${message.length}/900`}</span>

                  <button className="px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center space-x-2">
                    <Send size={16} />
                    <span>Kirim Pertanyaan</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      
          {/* Right Column - Additional Features */}
          <div className="space-y-6">
            {/* Popular Topics */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-medium mb-4">Topik Populer</h3>
              <div className="space-y-2">
                <PopularTopic label="PPN untuk E-commerce" count="125" />
                <PopularTopic label="PPh 21 Calculations" count="98" />
                <PopularTopic label="Tax Amnesty 2024" count="87" />
              </div>
            </div>
        
            {/* Expert Consultation */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-medium mb-4">Konsultasi dengan Ahli</h3>
              <div className="space-y-4">
                <ExpertCard
                  name="Dr. Siti Widya"
                  specialty="Tax Law Specialist"
                  rating="4.9"
                />
                <ExpertCard
                  name="Budi Santoso, M.Tax"
                  specialty="Corporate Tax Expert"
                  rating="4.8"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
   
}

export default Consultation