import './App.css';

import { BrowserRouter as Router, Route, Routes, HashRouter, Navigate } from 'react-router-dom';

import { Consultation, Dashboard, Login, Maintenance, Reconciliation } from './pages';

function App() {
  return (
    <Router>
    {/* <HashRouter> */}
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/tax-consultation" element={<Consultation />} />
        <Route path="/tax-reconciliation" element={<Reconciliation />} />

        <Route path="/maintenance" element={<Maintenance />} />

        <Route path="*" element={<Navigate to="/maintenance" replace />} />
      </Routes>
    {/* </HashRouter> */}
    </Router>
  );
}

export default App;
